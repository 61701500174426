import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function DuyuruDetay() {
  const { linkName } = useParams();
  const [duyuru, setDuyuru] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/Duyurular.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const foundDuyuru = data.find((item) => item.linkName === linkName);
        if (foundDuyuru) {
          setDuyuru(foundDuyuru);
        } else {
          setError('Duyuru bulunamadı.');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Veriler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      });
  }, [linkName]);

  const formatDate = (dateString) => {
    const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', options);
  };

  if (error) {
    return <div className="container mx-auto px-4 py-32 bg-gray-50">{error}</div>;
  }

  if (!duyuru) {
    return <div className="container mx-auto px-4 py-32 bg-gray-50">Yükleniyor...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-32 bg-gray-50">
      {duyuru.image && <img src={duyuru.image} alt={duyuru.title} className="w-full lg:h-[30rem] object-contain rounded-lg mb-4" />}
      <h1 className="text-4xl font-bold text-gray-800 mb-4">{duyuru.title}</h1>
      <p className="text-blue-600 font-semibold mb-2">Tarih: {formatDate(duyuru.date)} - Saat: {duyuru.time}</p>
      <p className="text-gray-700 mb-4">{duyuru.description2}</p>
      <p className="text-gray-700 mb-4">{duyuru.description}</p>
      <p className="text-gray-700 mb-4">Kayıt için: <a href={`mailto:${duyuru.registration}`} className="text-blue-600 font-semibold">{duyuru.registration}</a></p>
      <p className="text-gray-600 italic">{duyuru.note}</p>
    </div>
  );
}